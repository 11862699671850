import React, { Component } from "react";
import "../styles/main.scss";
import "../styles/404.scss";

export default class NotFound extends Component {
    render() {
        return(
            <div className="code404">
                <div className="nest">
                    <img src="/assets/404.svg" alt="404" />

                    <div className="code">404</div>

                    <div className="text">Aradığınız sayfa bulunamadı.</div>
                </div>
            </div>
        )
    }
}